export function getIEVersion() {
    return navigator.appName === 'Microsoft Internet Explorer'
        && /MSIE ([0-9]+[.0-9]*)/.exec(navigator.userAgent) !== null
        ? parseFloat(RegExp.$1)
        : null;
}

export function isWinXp() {
    return navigator.userAgent.indexOf('NT 5.1') >= 0;
}

export function isIE7() {
    const v = getIEVersion();
    return v !== null && v <= 7;
}

export function checkBrowser() {
    if (isWinXp()) {
        return 'You appear to be trying to access this site using Windows XP';
    }

    if (isIE7()) {
        return 'You appear to be trying to access this site using IE7 (or lower)*';
    }

    return true;
}

export function getSearchParams() {
    const urlSearch = new URLSearchParams(window.location.search);

    return {
        key: urlSearch.get('key'),
        contactId: urlSearch.get('contactId'),
        userName: urlSearch.get('username'),
        token: urlSearch.get('loginToken')
    };
}

export function getTagEl() {
    return document.getElementById("loginTitle");
}

export function getFaviconId() {
    return document.getElementById("favicon");
}

export function getFaviconMaskId() {
    return document.getElementById("faviconMask");
}